import React from 'react';
import {NavLink} from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {hostSignupTypeform} from './Links';

const logo = "/assets/images/popperi-small.png"

interface Props {
  active: string;
}

const PopperiNav = (props: Props) => {
    console.log('props.active is popups', props.active==='popups');
    return (
        <Navbar bg="light" expand="lg" sticky="top">
          <Navbar.Brand href="/">
            <img
              src={logo}
              height="40"
              className="d-inline-block align-top"
              alt="Popperi logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle/>
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link className="text-small"><NavLink to="/vendors" className={props.active === 'vendors' ? 'active-page' : ''}>Become a Vendor</NavLink></Nav.Link>
            <Nav.Link href={hostSignupTypeform} className="text-small">Host a Popup</Nav.Link>
            <Nav.Link className="text-small"><NavLink to="/popups" className={props.active === 'popups' ? 'active-page' : ''}>Our Popups</NavLink></Nav.Link>
            <Nav.Link className="text-small"><NavLink to="/login" className={props.active === 'login' ? 'active-page' : ''}>Login</NavLink></Nav.Link>
          </Navbar.Collapse>
        </Navbar>
    )
}
export default PopperiNav;
import React, { useRef } from 'react';
import StackGrid, { transitions, easings } from "react-stack-grid";

interface Image {
    key: string;
    img: string;
    vendor: string;
    description: string;
    url: string;
}

const renderCard = (image: Image) => (
  <a data-isotope-item href={image.url} target="_blank" rel="noopener noreferrer" className="m-0 p-0">
    <div className="card  bg-primary text-white min-vh-10 m-0 p-0" key={image.key}>
        <div className="d-block bg-gradient rounded-top position-relative">
            <img className="card-img-top" src={image.img} alt="Inside a retail store" />
        </div>
        <div className="card-body p-3">
            <h3 className="text-white p-0 m-0 text-center">{image.vendor}</h3>
        </div>
    </div>  
  </a>
)

let images = [
  { key: 'k1', img: '/assets/images/popups/april-7th-2020/amandajorgenson.jpg', vendor: 'Amanda M Jorgenson', url: 'https://www.instagram.com/amandamjorgenson/' },
  { key: 'k2', img: '/assets/images/popups/april-7th-2020/cathywu.JPG', vendor: 'Cathy Wu',  url: 'https://www.instagram.com/itscathywu/' },
  { key: 'k3', img: '/assets/images/popups/april-7th-2020/jamieslye.jpg', vendor: 'Jamie Slye', url: 'https://www.instagram.com/jamieslye/' },
  { key: 'k4', img: '/assets/images/popups/april-7th-2020/porpeartifacts.jpg', vendor: 'Porpe Artifacts', url: 'https://www.instagram.com/porpeartifacts/' },
  { key: 'k5', img: '/assets/images/popups/april-7th-2020/puddlesbarkery.JPG', vendor: 'Puddles Barkery', url: 'https://www.instagram.com/puddlesbarkery/' },
  { key: 'k6', img: '/assets/images/popups/april-7th-2020/samanthaslaterstudio.jpg', vendor: 'Samantha Slater Studio', url: 'https://www.instagram.com/samanthaslaterstudio/' },
  { key: 'k7', img: '/assets/images/popups/april-7th-2020/lexlunastudio.jpg', vendor: 'Lexa Luna Studio', url: 'https://www.instagram.com/lexalunastudio/' },
  { key: 'k8', img: '/assets/images/popups/april-7th-2020/whitewolfgoods.jpg', vendor: 'White Wolf Goods', url: 'https://www.instagram.com/whitewolfgoods/' },
  /*{ key: 'k9', img: '/assets/images/popups/april-7th-2020/bathingbeauteas.jpg', vendor: 'Bathing Beauteas', url: 'https://www.instagram.com/bathingbeauteas/' },*/
  { key: 'k10', img: '/assets/images/popups/april-7th-2020/alldogkind.jpg', vendor: 'All Dog Kind', url: 'https://www.instagram.com/alldogkind/' }
] as Image[];

const shuffle = function(a: any[]) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
images = shuffle(images);

function VendorGrid() {  
    return (
        <StackGrid
            monitorImagesLoaded
            columnWidth={ 255 }          
            duration={600}
            gutterWidth={24}
            gutterHeight={24}
            easing={easings.cubicOut}
            appearDelay={60}
            appear={transitions.appear}
            appeared={transitions.appeared}
            enter={transitions.enter}
            entered={transitions.entered}
            leaved={transitions.leaved}>
            {images.map(renderCard)}
        </StackGrid>
    )
}
export default VendorGrid;
import React, { useState, useContext } from "react";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "./Accounts";

export default () => {
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");

  interface AppContextInterface {
    getSession: Function,
    authenticate: Function,
    logout: Function
  }

  const context = useContext(AccountContext);
  const getSession = context?.getSession;
  const authenticate = context?.authenticate;

  const onSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    if (getSession && authenticate) {
      getSession().then((user: any, email: any ) => {
        authenticate(email, password).then(() => {
          const attributes = [
            new CognitoUserAttribute({ Name: "email", Value: newEmail })
          ];

          user.updateAttributes(attributes, (err: any, results: any) => {
            if (err) console.error(err);
            console.log(results);
          });
        });
      });
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input
          value={newEmail}
          onChange={event => setNewEmail(event.target.value)}
        />

        <input
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <button type="submit">Change email</button>
      </form>
    </div>
  );
};
import React, { useState, useContext } from "react";
import { AccountContext } from "./Accounts";

export default () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const context = useContext(AccountContext);
  const getSession = context?.getSession;
  const authenticate = context?.authenticate;

  const onSubmit = (event: { preventDefault: () => void; }) => {
    event.preventDefault();

    if (getSession && authenticate) {
      getSession().then(( user:any, email:any ) => {
        authenticate(email, password).then(() => {
          user.changePassword(password, newPassword, (err: any, result: any) => {
            if (err) console.error(err);
            console.log(result);
          });
        });
      });
    }
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input
          value={password}
          onChange={event => setPassword(event.target.value)}
        />

        <input
          value={newPassword}
          onChange={event => setNewPassword(event.target.value)}
        />

        <button type="submit">Change password</button>
      </form>
    </div>
  );
};
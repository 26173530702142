import React, { useState, useEffect, useContext } from "react";
import { AccountContext } from "./Accounts";
import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";

export default () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const context = useContext(AccountContext);
  const getSession = context?.getSession;

  useEffect(() => {
    if (getSession) {
      getSession().then(() => {
        setLoggedIn(true);
      });
    }
  }, []);

  return (
    <div>
      {loggedIn && (
        <>
          <h1>Settings</h1>
          <ChangePassword />
          <ChangeEmail />
        </>
      )}
    </div>
  );
};
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Landing from './components/landing/Landing';
//import PopupInfo from './components/popup/PopupInfo';
import Popup from './components/popup/Popup';
import Playground from './components/auth/Playground';
import Vendors from './components/vendors/Vendors';
import Popups from './components/popups/Popups';
import LoginPage from './components/auth/LoginPage';
import ScrollToTop from './components/helpers/ScrollToTop';

ReactDOM.render(
    <Router>
        <ScrollToTop>
            <Switch>
                <Route exact path="/" component={Landing}></Route>
                <Route exact path="/signup" component={Playground}></Route>
                <Route exact path="/vendors" component={Vendors}></Route>
                <Route exact path="/popups/april-7th-2020">
                    <Popup />
                </Route>
                <Route exact path="/popups" component={Popups}></Route>
                <Route exact path="/login" component={LoginPage}></Route>
            </Switch>
        </ScrollToTop>    
    </Router>,
    document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { Account } from "./Accounts";
import Signup from "./Signup";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import Status from "./Status";
import Settings from "./Settings";

function Playground() {
  return (
    <Account>
      <Status />
      <Signup />
      <Login />
      <ForgotPassword />
      <Settings />
    </Account>
  );
};

export default Playground;
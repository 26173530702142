import React from "react";
import PopperiNav from '../shared/PopperiNav'; 
import PopperiFooter from '../shared/PopperiFooter';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function LoginPage() {
  return (
    <div className="Vendors">
    <PopperiNav active='login'></PopperiNav>

    <div data-overlay className="min-vh-100 bg-light d-flex flex-column justify-content-md-center mt-0 pt-0">
        <section className="py-3 mt-0 pt-0">
          <div className="container mt-0 pt-0">
            <div className="row justify-content-center mt-0 pt-0 mb-4">
              <div className="col-xl-4 col-lg-5 col-md-6 mt-0 pt-0 mb-4">
                <div className="card card-body shadow mt-0 mb-4">
                  <h1 className="h5 text-center">Login</h1>
                  <form>
                    <div className="form-group">
                      <input type="email" className="form-control" name="sign-up-email" placeholder="Email Address" />
                    </div>
                    <div className="form-group">
                      <input type="password" className="form-control" name="sign-up-password" placeholder="Password" />
                      <div className="text-right text-small mt-2">
                        <a href="account-forgot-password.html">Forgot Password?</a>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox text-small">
                        <input type="checkbox" className="custom-control-input" id="sign-in-remember" />
                        <label className="custom-control-label" htmlFor="sign-in-remember">Remember me next time</label>
                      </div>
                    </div>
                    <button className="btn btn-primary btn-block" type="submit">Sign In</button>
                  </form>
                </div>
                {/*
                <div className="text-center text-small mt-3">
                  Don't have an account? <a href="account-sign-up-simple.html">Sign up here</a>
                </div>
                */}
              </div>
            </div>
          </div>
        </section>
    </div>
    <section className="bg-light pb-0 mb-0">
        <div className="divider divider-bottom bg-primary" />
    </section>
    <PopperiFooter></PopperiFooter>
</div>
  );
};

export default LoginPage;
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {customerTypeform, facebookURL, twitterURL, instagramURL} from '../shared/Links';

const logoWhite = "/assets/images/popperi-white-small.png";

class PopperiFooter extends React.Component {

    getYear():number {
        var d = new Date();
        return d.getFullYear();
    }

    render() {
        return (
            <footer className="bg-primary text-white links-white pb-0 footer-1">
                <Container>
                    <div className="row justify-content-center">
                        <div className="col-xl-auto mr-xl-5 col-md-3">
                            <h5>Popperi</h5>
                            <ul className="nav flex-row flex-md-column">
                                {/*<li className="nav-item mr-3 mr-md-0">
                                <a href="company-about-1.html" className="nav-link fade-page px-0 py-2">Company</a>
                                </li>*/}
                                <li className="nav-item mr-3 mr-md-0">
                                <a href="mailto:hello@popperi.com" className="nav-link fade-page px-0 py-2">Contact</a>
                                </li>
                                {/*}
                                <li className="nav-item mr-3 mr-md-0">
                                <a href="careers-1.html" className="nav-link fade-page px-0 py-2">Careers</a>
                                </li>*/}
                            </ul>
                        </div>
                        <div className="col-xl-auto mr-xl-5 col-md-3">
                            <h5>Social</h5>
                            <ul className="nav flex-row flex-md-column">
                                <li className="nav-item mr-3 mr-md-0">
                                <a href={instagramURL} className="nav-link fade-page px-0 py-2">Instagram</a>
                                </li>
                                <li className="nav-item mr-3 mr-md-0">
                                <a href={facebookURL} className="nav-link fade-page px-0 py-2">Facebook</a>
                                </li>
                                <li className="nav-item mr-3 mr-md-0">
                                <a href={twitterURL} className="nav-link fade-page px-0 py-2">Twitter</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-auto mt-2 mt-md-5 mt-lg-0">
                            <h5>Newsletter</h5>
                            <p className="pb-2">We send infrequent updates about popups<br/>you might be interested in near you.</p>

                            <a href={customerTypeform} target="_blank" rel="noopener noreferrer" className="btn btn-secondary btn-sm mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0 text-white" >Subscribe Now</a>
                            {/*
                            <div className="card card-body bg-white">
                                <p>Subscribe for annoucements and updates.</p>
                                
                                <form data-form-email noValidate action="/forms/mailchimp.php">
                                    <div className="d-flex flex-column flex-sm-row form-group">
                                        <input className="form-control mr-sm-2 mb-2 mb-sm-0 w-auto flex-grow-1" name="email" placeholder="Email Address" type="email" required />
                                        <button type="submit" className="btn btn-secondary btn-loading" data-loading-text="Sending">
                                        <svg className="icon bg-white" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                            <title>Icon For Loading</title>
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <g>
                                                <polygon points="0 0 24 0 24 24 0 24" opacity={0} />
                                            </g>
                                            <path d="M12,4 L12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,10.9603196 17.7360885,9.96126435 17.2402578,9.07513926 L18.9856052,8.09853149 C19.6473536,9.28117708 20,10.6161442 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,7.581722 7.581722,4 12,4 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) " />
                                            </g>
                                        </svg>
                                        <span>Go</span>
                                        </button>
                                    </div>
                                    <div data-recaptcha data-sitekey="INSERT_YOUR_RECAPTCHA_V2_SITEKEY_HERE" data-size="invisible" data-badge="bottomleft" />
                                    <div className="d-none alert alert-success w-100" role="alert" data-success-message>
                                        Thanks, a member of our team will be in touch shortly.
                                    </div>
                                    <div className="d-none alert alert-danger w-100" role="alert" data-error-message>
                                        Please fill all fields correctly.
                                    </div>
                                    <div className="text-small text-muted">We never spam or share your email address.</div>
                                </form>  
                            </div>
                            */}
                        </div>
                    </div>
                </Container>

                <div className="footer-legal bg-dark">
                    <Container>
                        <Row className="flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-left">
                            <Col className="col-auto">
                                <img
                                src={logoWhite}
                                height="35"
                                className="d-inline-block align-top"
                                alt="Popperi logo"
                                />
                            </Col>
                        </Row>
                        <Row className="flex-column flex-lg-row align-items-center justify-content-center text-center text-lg-left pt-2">
                            <Col className="col-auto justify-content-center text-center">
                                <div className="text-muted">Made in Seattle with love.</div>
                                <div className="text-muted pb-2"><span role="img">🌧️</span></div>
                                <div className="text-muted small">© {this.getYear()} Popperi Inc. All rights reserved. <a href="#" target="_blank">Privacy</a> and <a href="#" target="_blank">Terms</a>.</div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        );
    }
}
export default PopperiFooter;
import React from 'react';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import {hostSignupTypeform} from '../shared/Links';

const vendorGifUrl = "/assets/images/vendor.gif";
const vendorImgUrl = "/assets/images/heroes/lauren-2-square-small-min.jpg";
const retailImgUrl = "/assets/images/spaces/retail.jpeg";
const corporateLobbyImgUrl = "/assets/images/spaces/lobby.jpeg";
const apartmentImgUrl = "/assets/images/spaces/apartment.jpeg";

class LandingContent extends React.Component {

    render() {
        return (
            <div className="LandingContent">

                <section className="p-0 border-top border-bottom row no-gutters text-dark">
                    <Col className="col-lg-7 col-xl-6">
                        <Container className="min-vh-lg-80 d-flex align-items-center">
                            <Row className="justify-content-center">
                            <Col className="col-md-10 col-xl-9 text-center text-lg-left">
                                <section>
                                    <div data-aos="fade-right">
                                    <h1 className="display-3 pb-2">Delightful Popup Shops Anywhere</h1>
                                    <p className="lead">
                                        Whether in person or online, we create vibrant places where makers and creative entrepreneurs can connect with their community to sell their work.
                                    </p>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row mt-4 mt-md-5 justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-delay={300}>
                                    {/*<a href="#" className="btn btn-primary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0">Find a Popup</a>*/}
                                    <Link to="/vendors" className="btn btn-primary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0">Become a Vendor</Link>
                                    <a href={hostSignupTypeform} className="btn btn-secondary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0">Host a Popup</a>
                                    </div>
                                </section>
                            </Col>
                            </Row>
                        </Container>
                    </Col>
                    <div className="col-lg-5 col-xl-6 d-lg-flex flex-lg-column">
                    <div className="divider divider-side transform-flip-y bg-white d-none d-lg-block"/>
                        <img className="bg-image" src={vendorImgUrl} data-aos="fade" alt="Vendor seated in a popup booth"/>
                        {/*                    
                        <Carousel interval={2500}>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="assets/img/heros/market-1-square.jpg"
                                alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="assets/img/heros/market-2-square.jpg"
                                alt="First slide"
                                />
                            </Carousel.Item>   
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src="assets/img/heros/market-3-square.jpg"
                                alt="First slide"
                                />
                            </Carousel.Item>       
                        </Carousel>
                        */}
                    </div>
                </section>
                <section className="bg-light o-hidden text-dark">
                    <Container>
                    <div className="row section-title justify-content-center text-center">
                        <div className="col-md-9 col-lg-8 col-xl-7">
                        <div className="display-4">Vending With Us</div>
                        <div className="lead">
                            We want to change the world through inclusive and community-centered popups. Will you join us?
                        </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-around">
                        <div className="col-md-9 col-lg-5" data-aos="fade-right">
                        <img src={vendorGifUrl} alt="An illustration of a customer buying a vase from a vendor." className="img-fluid" />
                        </div>
                        <div className="col-md-9 col-lg-6 col-xl-5 mt-1 mt-md-2 mt-lg-0" data-aos="fade-left">
                        <ol className="list-unstyled p-0">
                            <li className="d-flex align-items-start my-4 my-md-5">
                            <div className="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-secondary circle-number">
                                <div className="text-white h5 mb-0">1</div>
                            </div>
                            <div className="ml-3 ml-md-4">
                                <h4>Sign up for an account</h4>
                                <p>
                                    Just answer a few short questions about your business. The better we get to know you, the better we'll be at recommending popups.
                                </p>
                            </div>
                            </li>
                            <li className="d-flex align-items-start my-4 my-md-5">
                            <div className="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-secondary circle-number">
                                <div className="text-white h5 mb-0">2</div>
                            </div>
                            <div className="ml-3 ml-md-4">
                                <h4>Browse available popups</h4>
                                <p>
                                    When a popup catches your eye, just click to apply. We'll match you to the best spaces for connecting with your target customers.
                                </p>
                            </div>
                            </li>
                            <li className="d-flex align-items-start my-4 my-md-5">
                            <div className="rounded-circle p-3 p-sm-4 d-flex align-items-center justify-content-center bg-secondary circle-number">
                                <div className="text-white h5 mb-0">3</div>
                            </div>
                            <div className="ml-3 ml-md-4">
                                <h4>Pop up where your clients are</h4>
                                <p>
                                    We offer a diverse range of popups of different sizes and demographics so you can find the community where your business thrives. 
                                </p>
                            </div>
                            </li>
                        </ol>
                        </div>
                        <Link to="/vendors" className="btn btn-primary btn-lg mt-5">Become a Vendor</Link>
                    </div>
                    </Container>
                </section>
        
                <section className="bg-secondary links-white text-white pt-0 pb-1">
                    <div className="divider divider-top transform-flip-x bg-light" />
                    <Container>
                    <Row className="section-title justify-content-center text-center">
                        <Col className="col-md-9 col-lg-8 col-xl-7">
                        <div className="display-4">Our Mission</div>
                        <div className="lead">We strive to create community-centered experiences <br/> where you can shop and belong.</div>
                        </Col>
                    </Row>
                    </Container>
                </section>
        
                <section className="bg-white links-primary text-dark">
                    <Container>
                    <Row className="section-title justify-content-center text-center">
                        <Col className="col-md-9 col-lg-8 col-xl-7">
                                <div className="display-4">Pandemic Response</div>
                                <div className="lead pb-4">
                                    These are heart-wrenching times for small businesses that rely on in-person events. Here's what we're doing to help.
                                </div>
                                <h2 className="text-secondary pt-4">Introducing Virtual Popups!</h2>
                                <div className="body">While we're dissapointed to postpone in-person events for a while,<br/>we're excited to take the vibe of our popups online.</div>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                            <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">    
                            <div className="card bg-bright-muted h-100 card-rounded">
                                <div className="card-body">
                                <h3>Building Community</h3>
                                <p>Virtual popups get right to the heart of our mission of supporting local communities where people can meet and buy from local makers.</p>
                                </div>
                            </div>
                            </Col>
                            <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                            <div className="card bg-bright-muted h-100 card-rounded">
                                <div className="card-body">
                                <h3>Scheduled Programming</h3>
                                <p>We'll program out the day with livestreams, fun stories, and exciting announcements to create an engaging experience for both customers and vendors.</p>
                                </div>
                            </div>
                            </Col>
                            <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                            <div className="card bg-bright-muted h-100 card-rounded">
                                <div className="card-body">
                                <h3>Connecting Safely</h3>
                                <p>With virtual popups, we're creating a space where customers and vendors can stay connected despite the quarantines and lockdowns.</p>
                                </div>
                            </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center text-center mt-md-n4 pt-5">
                            <Col className="col-auto">
                            <Link to="/popups" className="btn btn-primary btn-lg text-white mt-5">See Our Popups</Link>
                            </Col>
                        </Row>

                    </Container>
                </section>

                <section className="bg-light text-dark">
                    <Container>

                        <Row className="section-title justify-content-center text-center">
                            <Col className="col-md-9 col-lg-8 col-xl-7">
                                <h3 className="display-4">Hosting a Popup</h3>
                                <div className="lead">
                                    Whether you're looking to attract new customers or entertain existing ones, popups turn any space into a vibrant place. 
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                            <div className="card h-100">
                                <div className="d-block bg-gradient rounded-top position-relative">
                                <img className="card-img-top" src={retailImgUrl} alt="Inside a retail store" />
                                </div>
                                <div className="card-body">
                                <h3>Retail Spaces</h3>
                                <p>
                                    Attract new customers and boost foot traffic during down times with a popup. We activate retail spaces with the best local vendors to fit the occasion.
                                </p>
                                {/*<a href="#" className="stretched-link">Read Story</a>*/}
                                </div>
                            </div>
                            </Col>
                            <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                            <div className="card h-100">
                                <div className="d-block bg-gradient rounded-top position-relative">
                                <img className="card-img-top" src={corporateLobbyImgUrl} alt="Inside a corporate lobby" />
                                </div>
                                <div className="card-body">
                                <h3>Corporate Lobbies</h3>
                                <p>
                                    Lobby looking a bit too starchy? Hosting a popup for your employees or corporate tennants can energize a building and add excitement to the workday.
                                </p>
                                {/*<a href="#" className="stretched-link">Read Story</a>*/}
                                </div>
                            </div>
                            </Col>
                            <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                            <div className="card h-100">
                                <div className="d-block bg-gradient rounded-top position-relative">
                                <img className="card-img-top" src={apartmentImgUrl} alt="Inside a community room of a luxury apartment" />
                                </div>
                                <div className="card-body">
                                <h3>Apartment Buildings</h3>
                                <p>
                                    Popups are the ultimate luxury amenity. Liven up that under-used community space. Bring the best in city shopping right to your tennants' doorsteps.
                                </p>
                                {/*<a href="#" className="stretched-link">Read Story</a>*/}
                                </div>
                            </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
                <section className="bg-light pt-1 pb-0">
                    <Container>
                        
                    <Row className="justify-content-center text-center mt-md-n4">
                        <Col className="col-auto">
                        <a href={hostSignupTypeform} className="btn btn-primary btn-lg">Get on the Waitlist</a>
                        <div className="body pt-4">Be first in line to host an in-person popup when things get back to normal!</div>                
                        </Col>
                    </Row>
                    </Container>
                    <div className="divider divider-bottom bg-primary mt-5" />
                </section>
            </div>
        );
    }
}
export default LandingContent;
import React from 'react';
import { withRouter } from "react-router";
import {RouteComponentProps} from "react-router";

// Type whatever you expect in 'this.props.match.params.*'
type PathParamsType = {

}

// Your component own properties
type PropsType = RouteComponentProps<PathParamsType> & {
    location: Location,
    children: any
}


class ScrollToTop extends React.Component<PropsType> {
    componentDidUpdate(prevProps: PropsType) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0)
      }
    }
  
    render() {
      return this.props.children
    }
}
  
export default withRouter(ScrollToTop)
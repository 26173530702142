import React from 'react';
import { RouteComponentProps } from 'react-router';    
import PopperiNav from '../shared/PopperiNav'; 
import PopperiFooter from '../shared/PopperiFooter';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import {customerTypeform, instagramURL} from '../shared/Links';
const vendorGifUrl = "/assets/images/virtual-cropped-min.gif";

interface Props {

}

interface State {

}

interface Props {

}

class Popups extends React.Component<Props, State> {

  componentDidMount() { 

  }


  render() {
    return (
      <div className="Popups">
        <PopperiNav active='popups'></PopperiNav>

        <div className="bg-primary o-hidden" data-overlay>
            <section className="text-white pb-0">
                <div className="container pb-5">
                <div className="row justify-content-center text-center">
                    <div className="col-md-9 col-lg-8 col-xl-7">
                    <h1 className="display-3">We Organize Rad Popups</h1>
                    <p className="lead mb-0">Come meet local artists and makers and buy their stuff.</p>
                    </div>
                </div>
                </div>
                <div className="position-absolute w-50 h-100 top left" data-jarallax-element="100 50">
                <div className="blob blob-4 bg-secondary w-100 h-100 bottom right" />
                </div>
                <div className="divider divider-bottom bg-white" />
            </section>
            </div>
            <section className="bg-white text-dark pb-4">
                <Container className="pb-4 mb-4">
                    <Row className="section-title justify-content-center text-center">
                        <Col className="col-md-9 col-lg-8 col-xl-7">
                            <div className="display-4">Virtual Popups</div>
                            <div className="lead pb-4 mb-4">We're excited to bring the unique vibe and community shopping experience of our in-person popups online.</div>
                            <img src={vendorGifUrl} width={450} alt="An illustration of a customer buying a vase from a vendor." className="img-fluid" />
                        </Col>
                    </Row>

                    <Row className="pb-4">
                                <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">    
                                <div className="card bg-bright-muted text-black h-100 card-rounded">
                                    <div className="card-body">
                                    <h3>Curated Vendor Lineup</h3>
                                    <p>We curate the lineup of each virtual event to create <strong>intimate shopping experiences</strong> featuring a unique blend of creators.</p>
                                    </div>
                                </div>
                                </Col>
                                <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                                <div className="card bg-bright-muted text-black h-100 card-rounded">
                                    <div className="card-body">
                                    <h3>Surprise Announcements</h3>
                                    <p>Look for surprises from vendors, including livestreams, virtual studio tours, <strong>one-time deals</strong>, bonus gifts, and more.</p>
                                    </div>
                                </div>
                                </Col>
                                <Col className="col-md-6 col-lg-4 mb-3 mb-md-4 mb-lg-0">
                                <div className="card bg-bright-muted text-black h-100 card-rounded">
                                    <div className="card-body">
                                    <h3>Supporting Local</h3>
                                    <p>Local businesses need your support now more than ever. <strong>Shop local virtually</strong> and support your community!</p>
                                    </div>
                                </div>
                                </Col>
                        </Row>

                        <Row className="justify-content-center text-center pb-4 mb-4 mt-4">
                            <Col className="col-auto">    
                            <h2 className="text-secondary mt-4 pt-4">All the action happens on Instagram!</h2>
                            <div className="lead mt-4 mb-4 pb-2">Follow <a href={instagramURL} target="_blank" rel="noopener noreferrer">@PopperiPopups</a> and<br/>subscribe to our newsletter for updates.</div>
                            
                            <a href={customerTypeform} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0 mt-4" >Subscribe Now</a>
                            <div className="pt-2 text-small">News about your favorite local makers right to your inbox.</div>
                            
                            </Col>
                        </Row>
                </Container>
            </section>
            <section className="bg-light text-dark">
                <Container>
                    <Row className="section-title justify-content-center text-center p-0 m-0">
                        <Col className="col-md-9 col-lg-8 col-xl-7 justify-content-center text-center" >                    
                            <div className="display-4 pb-4">Our Next Event</div>
                            <Link to="/popups/april-7th-2020" className="btn-primary btn btn-lg">Tue Apr 7th</Link>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section className="bg-white">
                <Container>
                    <Row className="row section-title justify-content-center text-center p-0 m-0">
                        <Col className="col-md-9 col-lg-8 col-xl-7">
                            <h3 className="display-4">In-person events will resume soon</h3>
                            <div className="lead">Once quarantine life recedes, we'll be back to organizing popups at interesting places all over town.</div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary pb-0 text-white">
            <div className="container">
                <div className="row section-title justify-content-center text-center">
                <div className="col-md-9 col-lg-8 col-xl-7">
                    <div className="lead">
                        <div>See you at our next popup!</div>
                        <div className="pt-2"><span role="img" aria-label="fingers crossed emoji">🤞</span></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="divider divider-bottom bg-primary" />
        </section>


        <PopperiFooter></PopperiFooter>
      </div>
    );
  }
}
export default Popups;
import React from 'react';
import { RouteComponentProps } from 'react-router';    
import PopperiNav from '../shared/PopperiNav'; 
import PopperiFooter from '../shared/PopperiFooter';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import VendorGrid from './VendorGrid';
import {customerTypeform, instagramURL} from '../shared/Links';
const vendorGifUrl = "/assets/images/vendor.gif";

interface Props {

}

interface State {

}


class Popup extends React.Component<Props, State> {

  componentDidMount() { 

}


  render() {
    return (
      <div className="Popup">
        <PopperiNav active=''></PopperiNav>


        <div className="bg-secondary o-hidden" data-overlay>
            <section className="text-white pb-0">
                <div className="container pb-4">
                <div className="row justify-content-center text-center">
                    <div className="col-md-9 col-lg-8 col-xl-7">
                    <div className="display-3">Join us for a Virtual Popup</div>
                    <h1 className="pt-2">Apr 7th, 10am to 1pm PDT</h1>
                    <div className="lead links-white">Follow <a href="http://instagram.com/popperipopups" target="_blank" rel="noopener noreferrer">@PopperiPopups</a> on Instagram</div>
                    </div>
                </div>
                </div>
                <div className="position-absolute w-50 h-100 top right" data-jarallax-element="100 50">
                <div className="blob blob-4 bg-primary w-100 h-100 top left" />
                </div>
                <div className="divider divider-bottom bg-white" />
            </section>
            </div>
            <section className="bg-white text-dark pb-4">
                <Container className="pb-4 mb-4">
                    <Row className="section-title justify-content-center text-center mb-0">
                        <Col className="col-md-9 col-lg-8 col-xl-7">
                            <div className="display-4">Maker Lineup</div>
                            <div className="lead pb-4 mb-4">We're excited to bring you this magnificent collection of talented Pacific Northwest artists and makers.</div>
                        </Col>
                    </Row>

                    <Row>
                        <Container>
                        <VendorGrid></VendorGrid>
                        </Container>
                    </Row>

                </Container>
            </section>
            <section className="bg-light text-dark">
                <Container>
                <Row className="section-title justify-content-center text-center p-0 m-0">
                        <Col className="col-md-9 col-lg-8 col-xl-7 justify-content-center text-center" >                    
                            <div className="display-4">Live Shedule</div>
                            <div className="lead mt-4 mb-4 pb-4">Join us <a href={instagramURL} className="black-links" target="_blank" rel="noopener noreferrer">@PopperiPopups</a> on Instagram for live discussions and annoucements from makers throughout the day.</div>

                            <table className="table mt-4">
                                <thead>
                                    <tr>
                                    <th scope="col" className="text-left">Time</th>
                                    <th scope="col">Business</th>
                                    <th scope="col">Topic</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row" className="text-left">10:00</th>
                                        <td>Merchant Method</td>
                                        <td>Chris discusses the state of retail and the importance of video in making connections.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-left">10:30</th>
                                        <td>Samantha Slater Studio</td>
                                        <td>Get special tour Samantha's studio and an inside look at her works and process.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-left">10:45</th>
                                        <td>Jamie Slye</td>
                                        <td>Pick the perfect hat for your wardrobe in a virtual styling session with Jamie.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-left">11:15</th>
                                        <td>Porpe Artifacts</td>
                                        <td>Vicky explains the process of incorporating silk cacoons in her jewelry.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-left">11:30</th>
                                        <td>All Dog Kind</td>
                                        <td>See All Dog Kind's market booth setup, and learn how to get a custom GIF of your dog.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-left">11:45</th>
                                        <td>Amanda M Jorgenson</td>
                                        <td>Send a hand-drawn post card to someone you love courtesy of Amanda.</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className="text-left">12:00</th>
                                        <td>Puddles Barkery</td>
                                        <td>Meet owner and cheif-baker Kari and learn a frosting recipei for your pupcakes.</td>
                                    </tr>
                                </tbody>
                            </table>                        
                        </Col>
                </Row>
                </Container>
            </section>

            <section className="bg-secondary pb-0 text-white">
            <div className="container">
                <div className="row section-title justify-content-center text-center">
                <div className="col-md-9 col-lg-8 col-xl-7">
                    <div className="lead">
                        <div>See you at our next popup!</div>
                        <div className="pt-2"><span role="img" aria-label="fingers crossed emoji">🤞</span></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="divider divider-bottom bg-primary" />
        </section>


        <PopperiFooter></PopperiFooter>
      </div>
    );
  }
}
export default Popup;
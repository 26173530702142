import React from 'react';
import PopperiNav from '../shared/PopperiNav'; 
import PopperiFooter from '../shared/PopperiFooter';
import LandingContent from './LandingContent';

interface Props {

}

interface State {

}

class Landing extends React.Component {
  render() {
    return (
      <div className="Landing">
        <PopperiNav active=''></PopperiNav>
        <LandingContent></LandingContent>
        <PopperiFooter></PopperiFooter>
      </div>
    );
  }
}
export default Landing;

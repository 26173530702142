import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Accounts';

export default () => {
  const [status, setStatus] = useState(false);

  const context = useContext(AccountContext);
  const logout = context?.logout;
  const getSession = context?.getSession;

  useEffect(() => {
    if (getSession) {
      getSession()
        .then((session: any) => {
          console.log('Session:', session);
          setStatus(true);
        })
    }
  }, []);

  return (
    <div>
      {status ? (
        <div>
          You are logged in.
          <button onClick={logout as any}>Logout</button>
        </div>
      ) : 'Please login below.'}
    </div>
  );
};
import React from 'react';
import { RouteComponentProps } from 'react-router';    
import PopperiNav from '../shared/PopperiNav'; 
import PopperiFooter from '../shared/PopperiFooter';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {vendorOnlinePopupTypeform, instagramURL} from '../shared/Links';

const vendorImg = '/assets/images/heroes/sam-small-min.jpg';

interface Props {

}

interface State {

}

interface Props {

}

class Vendors extends React.Component<Props, State> {

  componentDidMount() { 

  }


  render() {
    return (
      <div className="Vendors">
          <PopperiNav active='vendors'></PopperiNav>


          <div className="o-hidden" data-overlay>
            <section className="pb-0">
                <div className="container">
                    <div className="row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
                        <div className="col-md-9 col-lg-6 col-xl-5 pl-lg-5 pl-xl-0 order-lg-2" data-aos="fade-left" data-aos-delay={250}>
                        <h1 className="display-3">Building Community Through Popups</h1>
                        <p className="lead">
                            We create vibrant places where makers and creative entrepreneurs can connect with their community to sell their work.
                        </p>
                        </div>
                        <div className="col-md-8 col-lg-6 mt-4 mt-md-5 mt-lg-0 order-lg-1" data-aos="fade-right">
                            <img src={vendorImg} alt="Image" className="rounded img-fluid shadow-lg" />
                        </div>
                    </div>
                </div>
                <div className="position-absolute w-50 h-100 top right" data-jarallax-element={50}>
                    <div className="blob bg-secondary opacity-20 w-100 h-100 top left" />
                </div>
                <div className="divider divider-bottom bg-secondary" />
            </section>
            </div>
            <section className="bg-secondary text-white pb-3">
            <div className="container">
                <Row className="section-title justify-content-center text-center">
                    <Col className="col-md-9 col-lg-8 col-xl-7">
                        <h3 className="display-4">Virtual Popups</h3>
                        <div className="lead pb-4 mb-4">We're excited to bring the vibe, community, and selling opportunities of our in-person popups online.</div>
                        <a href={vendorOnlinePopupTypeform} className="btn btn-primary btn-lg mx-sm-2 mx-lg-0 mr-lg-2 my-1 my-sm-0" >Apply Now</a>
                    </Col>
                </Row>
                <Row className="justify-content-center text-center">
                        <Col>
                        </Col>
                </Row>
            </div>
            </section>
            <section className="bg-light text-dark">
                <Container>
                    <Row className="section-title justify-content-center text-center p-0 m-0">
                        <Col className="col-md-9 col-lg-8 col-xl-7 justify-content-center text-center" >                    
                            <div className="display-4 pb-4">Our Next Event</div>
                            <h2 className="text-secondary">TUE APR 7TH, 10AM to 1PM PDT</h2>
                            <div className="lead">Free to apply, $25 if selected to participate.</div>
                            <div className="body">Follow <a href={instagramURL}>@PopperiPopups</a> for updates.</div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section>
            <div className="container">
                <div className="row section-title justify-content-center text-center m-0">
                    <div className="col-md-9 col-lg-8 col-xl-7">
                        <h3 className="display-4">How does it work?</h3>
                        <div className="lead pb-4">We’ll use our platform and sponsored advertisements to shine a spotlight on a curated group of businesses.</div>
                        
                        <div className="ml-3 ml-md-4 pt-4">
                                <h5 className="mb-1 pb-1 text-secondary">Curated Vendor Lineup</h5>
                                <p>
                                    We curate the lineup of each virtual event to create experiences that feel more intimate and personalized to customers, while giving each vendor some time in the spotlight.
                                </p>
                        </div>
                        <div className="ml-3 ml-md-4 pt-4">
                                <h5 className="mb-1 pb-1 text-secondary">Generating Excitement</h5>
                                <p>
                                    We encourage each participating vendor to come up with surprises for customers during the event that you can tease the week before, and announce during the popup. 
                                </p>
                        </div>
                        <div className="ml-3 ml-md-4 pt-4">
                                <h5 className="mb-1 pb-1 text-secondary">Directing Customers to You</h5>
                                <p>
                                Our posts, emails, and advertisements will direct customers to your Instagram page, where you can chat directly with them and make one-on-one sales, just like at an in-person popup.
                                </p>
                        </div>
                        <div className="ml-3 ml-md-4 pt-4">
                                <h5 className="mb-1 pb-1 text-secondary">Scheduled Programming</h5>
                                <p>
                                We'll program the day so your posts, livestreams, and special announcements will get maximal exposure to customers. We'll be the host of the show, but you're the main attraction.
                                </p>
                        </div>
                        <div className="ml-3 ml-md-4 pt-4">
                                <h5 className="mb-1 pb-1 text-secondary">Pooled Marketing</h5>
                                <p>
                                    By highlighting a group of vendors at a time, we can pool our marketing efforts to capture peoples' attentions. We'll coordinate sponsored social media posts and event branding.
                                </p>
                        </div>
                        <div className="ml-3 ml-md-4 pt-4 pb-0">
                                <h5 className="mb-1 pb-1 text-secondary">Participation Details</h5>
                                <p>
                                    We charge a small fee for participation to cover our marketing and operations costs, just like in-person popups. Different popups may have different marketing budgets, so fees may vary.
                                </p>
                        </div>
                        <div className="ml-3 ml-md-4 pt-4 pb-0">
                                <h5 className="mb-1 pb-1 text-secondary">Requirements</h5>
                                <p>
                                    We have three requirements. <strong>(1)</strong> You should be active on Instagram during the popup, <strong>(2)</strong> you should be experienced in selling online and shipping and have a clear and visible shipping policy for customers, and <strong>(3)</strong> you should have a special announcement or offering planned for the popup that you <strong><em>keep secret</em></strong> until the day of the event. If you have any questions, email us at <a href="mailto:hello@popperi.com">hello@popperi.com</a>.
                                </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>

            <section className="bg-light">
                <Container>
                    <Row className="row section-title justify-content-center text-center p-0 m-0">
                        <Col className="col-md-9 col-lg-8 col-xl-7">
                            <h3 className="display-4">In-person events will resume soon</h3>
                            <div className="lead">Once quarantine life recedes, we'll be back to organizing popups at interesting places all over town.</div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section className="bg-secondary pb-0 text-white">
            <div className="container">
                <div className="row section-title justify-content-center text-center">
                <div className="col-md-9 col-lg-8 col-xl-7">
                    <div className="lead">
                        <div>We hope you'll join us!</div>
                        <div className="pt-2"><span role="img" aria-label="fingers crossed emoji">🤞</span></div>
                    </div>
                </div>
                </div>
            </div>
            <div className="divider divider-bottom bg-primary" />
        </section>


        <PopperiFooter></PopperiFooter>
      </div>
    );
  }
}
export default Vendors;